<template>
  <div>
    <div v-show="errorMessage" v-html="errorMessage" class="alert-block">
    </div>
    <div class="users-fields">
        <input type="text" name="name" placeholder="Ваше имя" v-model="formField.name">
        <input type="text" name="email" placeholder="Ваш email" v-model="formField.email">
      </div>
      <textarea name="message" placeholder="Комментарий" v-model="formField.message"></textarea>
      <button @click="submit">Отправить</button>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import alertMixin from "@/mixins/alertMixin";
import validate from "@/mixins/validate";

export default {
  name: 'SendCommentForm',
  mixins: [
    alertMixin,
    validate
  ],
  props: {
    entity: {
      type: String,
    },
    entityId: {
      type: Number,
    },
  },
  data() {
    return {
      formField: {
        name:     '',
        email:    '',
        message:  '',
      },
      errorMessage: '',
    }
  },
  methods: {
    ...mapActions({
      sendComment: 'Comments/sendComment',
    }),
    ...mapMutations({
      setAlertMessage: 'Alert/setAlertMessage',
    }),
    submit() {
      if (!this.formField.name)
        this.errorMessage = 'Поле <span>имя</span> обезательно для заполнения';
      else if (!this.formField.email)
        this.errorMessage = 'Поле <span>email</span> обязательно для заполнения';
      else if (!this.validateEmail(this.formField.email))
        this.errorMessage = `${this.formField.email} - это не похоже на email...`;
      else if (!this.formField.message)
        this.errorMessage = 'Поле <span>сообщение</span> обязательно для заполнения';
      else
        this.errorMessage = '';

      if (!this.errorMessage) {
        let payload = {
          name: this.formField.name,
          email: this.formField.email,
          message: this.formField.message,
          entity: this.entity,
          entityId: this.entityId,
        }

        if (this.sendComment(payload)) {
          this.formField = {};
          this.showAlert('Комментарий добавлен');
        } else {
          this.showAlert(this.errorMessage, 1000, '#ff0000');
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .users-fields {
    display: flex;
    margin-bottom: 20px;

    input {
      min-width: 30%;
    }
  }

  input, textarea {
    display: block;
  }

  button {
    margin: 20px auto;
    display: block;
  }
</style>

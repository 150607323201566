<template>
  <div class="article-box">
    <div class="article-header">
      <span class="header-line">
          <a href="#" class="title">{{ currentArticle.title }}</a><span class="publisher-time">{{ currentArticle.created_at }}</span>
      </span>
      <span v-if="currentArticle.tags" class="header-line add-info">Теги: {{ currentArticle.tags }}</span>
      <!--      <span class="header-line add-info">Игра: <a href="">Silent Hill 2</a> | Теги: <a href="">Silent Hill</a>, <a href="">Новости</a></span>-->
      <span class="header-line">
        <div
            class="article-image"
            v-if="currentArticle.image"
            :style=articleImageStyle>
        </div>
      </span>
    </div>
    <div class="article-body" v-html="currentArticle.text_full"></div>
    <div class="article-footer">
      <!--      <span class="add-info">Оставлено  комментариев | Поставлено 5 <a href="#"><i class="fas fa-heart with-hover-v-1"></i></a></span>-->
      <span class="add-info">Оставлено {{ currentArticle.comments_count }} комментариев</span>
    </div>
    <SendCommentForm
        :entity=currentArticle.entity
        :entityId=currentArticle.id
    />
    <template v-for="comment in commentList" :key="comment.id">
      <CommentBlock
          :field=comment
      />
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SendCommentForm from "@/components/comments/SendCommentForm";
import CommentBlock from "@/components/comments/CommentBlock.vue";

export default {
  name: 'ArticleDetail',
  components: {
    CommentBlock,
    SendCommentForm,
  },
  data() {
    return {
      articleImageStyle: {}
    }
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    }
  },
  computed: {
    ...mapGetters({
      currentArticle: 'Articles/getCurrentArticle',
      commentList: 'Comments/getCommentList',
    }),
  },
  created() {
    this.fetchArticle({code: this.$route.params.slug, type: this.type});
  },
  mounted() {
  },
  watch: {
    currentArticle: function (article) {
      if (article) {
        this.init();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchArticle: 'Articles/fetchArticle',
      fetchCommentList: 'Comments/fetchCommentList',
    }),
    init() {
      this.fetchCommentList({
        entity:   this.currentArticle.entity,
        entityId: this.currentArticle.id,
      });

      if (this.currentArticle.image) {
        this.articleImageStyle.background = `url('${this.currentArticle.image}') no-repeat`;
        this.articleImageStyle.backgroundSize = `100% auto`;
        this.articleImageStyle.backgroundPosition = `center`;
      }
    }
  }
}
</script>

<template>
  <div>
    <ArticleDetail
        :slug="$route.params.slug"
        type="programs"
    />
  </div>
</template>

<script>
import ArticleDetail from "@/components/news/ArticleDetail";

export default {
  name: 'ProgramDetail',
  components: {
    ArticleDetail,
  },
}
</script>

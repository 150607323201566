<template>
  <div class="comment-box">
    <div class="comment-box-head">Комментарий {{ field.author_name }} от {{ formatDate() }}</div>
    <div class="message-box">{{ field.message }}</div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: "CommentBlock",
  props: {
    field: {
      type: Object,
    },
  },
  methods: {
    formatDate() {
      const date = dayjs(this.field.created_at, 'YYYY-MM-DD HH:mm');

      return date.format('D') + ' ' + this.getMonthName(date.format('M')) + ' ' + date.format('YYYY');
    },

    getMonthName(monthNumber) {
      const months = [
        'Января',
        'Февраля',
        'Марта',
        'Апреля',
        'Мая',
        'Июня',
        'Июля',
        'Августа',
        'Сентября',
        'Октября',
        'Ноября',
        'Декабря',
      ];

      return months[Number(monthNumber) - 1];
    },
  }
}
</script>

<style lang="scss" scoped>
.comment-box {
  margin-bottom: 40px;
}

.comment-box .comment-box-head {
  padding-bottom: 10px;
}

.comment-box .message-box {
  padding-top: 10px;
  border-top: 1px solid var(--main-border-color);
}

.in-developing {
  font-size: 22px;
  line-height: 40px;
  letter-spacing: 5px;
  margin-top: 20px;
}
</style>

import {mapMutations} from "vuex";

export default {
    methods: {
        ...mapMutations({
            setAlertMessage:    'Alert/setAlertMessage',
            setAlertTime:       'Alert/setAlertTime',
            setAlertColor:      'Alert/setAlertColor',
        }),
        showAlert(message, time = '', color = '') {
            if (message) {
                this.setAlertMessage(message);

                time ? this.setAlertTime(time) : this.setAlertTime(3000);
                color ? this.setAlertColor(color) : this.setAlertColor('#22cf95');
            }
        }
    }
}
